import React, {useCallback, useEffect, useState} from 'react'
import './dashboard.css'
import Footer from '../../components/footer/footer'
import ClicksCard from '../../components/clicks-card/clicks-card'
import SocialCards from '../../components/social-cards/social-cards'

const Dashboard = () => {
  const [isOpened, setIsOpened] = useState(false)

  const toggleSidebar = () => {
    setIsOpened(prevState => !prevState)
  }

  const handleEscape = useCallback(event => {
    if (event.keyCode === 27) setIsOpened(false)
  }, [])

useEffect(() => {
  if (isOpened) document.addEventListener('keydown', handleEscape, false)
    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
}, [handleEscape, isOpened])

  return (
    <div class="wrapper-class">
      <div className="link-navbar-toggle" onClick={toggleSidebar}><span className="toggle-icon">☰</span></div>
      <div style={{'display' : 'flex', 'z-index' : 999}}>
        <div style={{'width' : 100+'%'}}>
        <div className="overview-section">
            <ClicksCard />
            <div>
              <div className="socials-overview">
                <SocialCards social_id='2' />
                <SocialCards social_id='3' />
              </div>
              <div className="socials-overview">
                <SocialCards social_id='1'/>
                <SocialCards social_id='4'/>
              </div>
            </div>
        </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Dashboard;