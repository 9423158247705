import React from 'react'
import './clicks-card.css'
import StatsUp from '../../assets/images/icon-up.svg'
import StatsDown from '../../assets/images/icon-down.svg'

const ClicksCard = () => {
    return (
        <>
            <div class="card-item" id="facebook">
            <hr/>
            <div class="card-title">
                <h3>LinkName</h3>
            </div>
            <div class="card-content">
                <h2>1987</h2>
                <h4>CLICKS</h4>
                <p> <img src={StatsUp} alt="Up"/>12 Clicks Today</p>
            </div>
            </div>
        </>
    )
}

export default ClicksCard