import React, {useState} from 'react'
import './footer.css'
import Facebook from '../../assets/images/icon-facebook.svg'
import Twitter from '../../assets/images/icon-twitter.svg'
import Pinterest from '../../assets/images/icon-pinterest.svg'
import Instagram from '../../assets/images/icon-instagram.svg'

const Footer = () => {
  return (
    <footer>
        <div className="footer">
          <div className="footer-logo">
            <h3>Rdre</h3>
          </div>
          <div className="footer-text">
            <div className="footer-section">
              <ul>
                <li>Built with love by <a href="https://twitter.com/olamideaboyeji">Olamide Aboyeji</a> and <a href="https://twitter.com/tunmiseoyefeso">Oyefeso Oluwatunmise.</a></li>
              </ul>
            </div>
          </div>
          <div className="footer-section">
            <div className="social-details">
              <ul>
                <li><a href="https://www.twitter.com"><img src={Facebook} alt="facebook" /></a></li>
                <li><a href="https://www.twitter.com"><img src={Twitter} alt="twitter" /></a></li>
                <li><a href="https://www.twitter.com"><img src={Pinterest} alt="pinterest" /></a></li>
                <li><a href="https://www.twitter.com"><img src={Instagram} alt="instagram" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
  );
}

export default Footer;