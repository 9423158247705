import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import './pricing.css'

const PricingComponent = () => {
  return (
    <section className="pricing-section">
      <div className="pricing-section-header">
        <h1>Our Pricing</h1>
        <span
          >Annually
          <label className="switch">
            <input type="checkbox" id="toggle-btn"/>
            <span className="slider round"></span>
          </label>
          Monthly</span
        >
      </div>
      <div className="pricing-cards monthly">
        <div className="card">
          <h3>Basic</h3>
          <p className="price">Free</p>
          <ul>
            <li>Shorten Links</li>
            <li> - </li>
            <li> - </li>
          </ul>
          <Link className="cta-btn" to="/pricing">Learn More</Link>
        </div>
        <div className="card middle">
          <h3>Professional</h3>
          <p className="price">Coming..</p>
          <ul>
            <li>Shorten Links</li>
            <li>Track Links Statistics</li>
            <li>Track SM engagement</li>
          </ul>
          <Link className="cta-btn" to="/pricing">Learn More</Link>
        </div>
        <div className="card">
          <h3>Master</h3>
          <p className="price">Coming..</p>
          <ul>
            <li>Shorten Links</li>
            <li>Track Links Statistics</li>
            <li> - </li>
          </ul>
          <Link className="cta-btn" to="/pricing">Learn More</Link>
        </div> 
      </div>
      <div className="pricing-cards yearly">
        <div className="card">
            <h3>Basic</h3>
            <p className="price">Free</p>
            <ul>
              <li>Shorten Links</li>
              <li> - </li>
              <li> - </li>
            </ul>
            <Link className="cta-btn" to="/pricing">Learn More</Link>
          </div>
          <div className="card middle">
            <h3>Professional</h3>
            <p className="price">Coming..</p>
            <ul>
              <li>Shorten Links</li>
              <li>Track Links Statistics</li>
              <li>Track SM engagement</li>
            </ul>
            <Link className="cta-btn" to="/pricing">Learn More</Link>
          </div>
          <div className="card">
            <h3>Master</h3>
            <p className="price">Free</p>
            <ul>
              <li>Shorten Links</li>
              <li>Track Links Statistics</li>
              <li> - </li>
            </ul>
            <Link className="cta-btn" to="/pricing">Learn More</Link>
          </div> 
      </div>
    </section>
  );
}

export default PricingComponent;
