import React, {useState} from 'react'
import './homepage.css'
import NavBar from '../../components/navbar/navbar'
import Footer from '../../components/footer/footer'
import Banner from '../../components/banner/banner'
import Features from '../../components/features/features'
import Shortener from '../../components/shortener/shortener'
import LandingPage from '../../components/landing/landing'

const HomePage = () => {
  
  document.addEventListener("DOMContentLoaded", function(event) {
    let box = document.querySelector('#statistics');
    let style = window.getComputedStyle(box);
    let height = parseInt(style.height);
    let newHeight = height + 30;
    document.getElementById('statistics').style.height = newHeight;
    console.log(height)
    console.log(newHeight)
  });

  return (
    <>
        <NavBar/>
        <LandingPage/>
        <Shortener/>
        <Features/>
        <Banner/>
        <Footer/>
    </>
  );
}

export default HomePage;