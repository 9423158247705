import React from 'react'
import './landing.css'
import Illustration from '../../assets/images/illustration-working.svg'

const LandingPage = () => {
    return (
        <section className="section-1">
            <div className="header-content">
                <div className="header-text">
                    <h2>Shorter links + unique names</h2>
                    <p>Shorten your links with unique names to build your brand’s recognition for free<br/> 
                    Sign in to get detailed insights on how your links are performing.</p>
                    <button className="btn-header">Get Started</button>
                </div>
                <div className="header-img">
                    <img src={Illustration} alt="illustration"/>
                </div>
            </div>
        </section>
    )
}

export default LandingPage