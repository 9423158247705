import React, {useState} from 'react'
import {Switch, Route, useHistory, Redirect} from 'react-router-dom'
import './App.css'

import HomePage from './pages/homepage/homepage'
import LoginPage from './pages/login/LoginPage'
import PricingPage from './pages/pricing/PricingPage'
import Dashboard from './pages/dashboard/dashboard'

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={HomePage}/>
        <Route path="/p/pricing" component={PricingPage} />
        <Route path="/p/login" component={LoginPage} />
        <Route path="/p/dashboard" component={Dashboard}/>
      </Switch>
    </div>
  );
}

export default App;
