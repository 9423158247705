import React from 'react'
import './social-cards.css'
import StatsUp from '../../assets/images/icon-up.svg'
import StatsDown from '../../assets/images/icon-down.svg'
import Facebook from '../../assets/images/icon-facebook copy.svg'
import Instagram from '../../assets/images/icon-instagram copy.svg'
import WhatsApp from '../../assets/images/icon-whatsapp copy.png'
import Twitter from '../../assets/images/icon-twitter copy.svg'

const SocialCards = (props) => {
    console.log(props.social_id)
    return (
        <>
            <div class="card-item-overview" id="Bad">
            <hr/>
            <div class="card-title-overview">
                <h3>Clicks</h3>
                <img src={ 
                (props.social_id === "1") ? Facebook : (props.social_id === "3") ? Twitter : (props.social_id === "4") ? WhatsApp : Instagram
                } alt="icono facebook"/>
            </div>
            <div class="card-content">
                <h2>52</h2>
                <p> <img src={StatsUp} alt="Down"/>2%</p>
            </div>
            </div>
        </>
    )
}

export default SocialCards