import React, {useState} from 'react'
import './PricingPage.css'
import NavBar from '../../components/navbar/navbar'
import Footer from '../../components/footer/footer'
import PricingComponent from '../../components/pricing/pricing'

const PricingPage = () => {
  return (
    <>
      <NavBar/>
      <PricingComponent/>
      <Footer/>
    </>
  );
}

export default PricingPage;
