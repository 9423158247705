import React, {useState} from 'react'
import './banner.css'

const Banner = () => {
  return (
    <div>
        <div className="boost-link">
            <h2>Boost Your Links Today</h2>
            <button className="btn-boost">Get Started</button>
        </div>
    </div>
  );
}

export default Banner;