import React, { useState , useContext } from "react";
import {AuthContext} from "../../App";
import { Redirect } from "react-router-dom";
import styles from "./login.css";

const Forms = (props) => {

    document.addEventListener("DOMContentLoaded", function(event) {
        const signUpButton = document.getElementById('signUp');
        const signInButton = document.getElementById('signIn');
        const container = document.getElementById('login-container');
        const signUpContainer = document.getElementById('sign-up-container');

        signUpButton.addEventListener('click', () => {
        container.classList.add("right-panel-active");
        });

        signInButton.addEventListener('click', () => {
        container.classList.remove("right-panel-active");
        });
    });

    function showSignUp ()  {
            const signUpContainer = document.getElementById('sign-up-container');
            signUpContainer.style.zIndex = "3";
            signUpContainer.style.opacity = "1";
            signUpContainer.style.width= "100%";
    }

    function hideSignUp ()  {
            const signUpContainer = document.getElementById('sign-up-container');
            signUpContainer.style.zIndex = "2";
            signUpContainer.style.opacity = "0";
    }

    return (
        <div className="form-body">
        <div class="login-container" id="login-container">
            <div class="form-container sign-up-container" id="sign-up-container">
                <form className="login-form" action="#">
                    <h1>Create Account</h1>
                    <div class="social-container">
                        <a href="#" class="social"><i class="fab fa-facebook-f"></i></a>
                        <a href="#" class="social"><i class="fab fa-google-plus-g"></i></a>
                        <a href="#" class="social"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                    <span>or use your email for registration</span>
                    <input type="text" placeholder="Name" />
                    <input type="email" placeholder="Email" />
                    <input type="password" placeholder="Password" />
                    <button>Sign Up</button>
                    <p class="overlay-replace">Existing user? <span onClick={() => hideSignUp()}>Sign In</span></p>
                </form>
            </div>
            <div class="form-container sign-in-container">
                <form className="login-form" action="#">
                    <h1>Sign in</h1>
                    <div class="social-container">
                        <a href="#" class="social"><i class="fab fa-facebook-f"></i></a>
                        <a href="#" class="social"><i class="fab fa-google-plus-g"></i></a>
                        <a href="#" class="social"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                    <span>or use your account</span>
                    <input type="email" placeholder="Email" />
                    <input type="password" placeholder="Password" />
                    <a href="#">Forgot your password?</a>
                    <button>Sign In</button>
                    <p class="overlay-replace">New user? <span onClick={() => showSignUp()}>Sign Up</span></p>
                </form>
            </div>
            <div class="overlay-container">
                <div class="overlay">
                    <div class="overlay-panel overlay-left">
                        <h1>Welcome Back!</h1>
                        <p>To keep connected with us please login with your personal info</p>
                        <button class="ghost" id="signIn">Sign In</button>
                        
                    </div>
                    <div class="overlay-panel overlay-right">
                        <h1>Hello, Friend!</h1>
                        <p>Enter your personal details and start journey with us</p>
                        <button class="ghost" id="signUp">Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
  }

export default Forms;