import React, { useState } from 'react'
import './shortener.css'

const Shortener = () => {
    const [link, setLink] = useState('');
    const [unique, setUnique] = useState('');
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const submit = (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setFetching(true);
        fetch('/new', {
            method: 'POST',
            headers : {
                'Content-Type' : 'application/json',
            },
            body : JSON.stringify({ originalUrl : link, unique_name : unique })
        })
        .then(res => res.json())
        .then(data => {
            if(!data.ok) {
                setError(data.msg);
            } else {
                setSuccess('Your shortened link is ' + data.newUrl);
            }
        })
        .finally(() => {
            setFetching(false);
        })
    }
    return (
        <section className="short">
            <form className="shortener-form" onSubmit={submit}>
                <div className="form-control">
                    <div className="input1"><input onChange={e => setLink(e.target.value)} type="url" name="shorten" placeholder="enter original link" required/></div>
                        <div className="input2">
                            {/* <span className="shorten-overlay">rdre.me/</span> */}
                            <input onChange={e => setUnique(e.target.value)} type="text" name="shorten" placeholder="enter unique_name (rdre.me/unique_name)" required/></div>
                            <button disabled={fetching} className="btn-shorten">{fetching ? '...' : 'Shorten Link'}</button>
                        </div>
                        <p className='errorMsg'>{error}</p>
                        <p className='successMsg'>{success}</p>
            </form>
        </section>
    )
}

export default Shortener