import React from 'react'
import './features.css'
import BrandRecognition from '../../assets/images/icon-brand-recognition.svg'
import DetailedRecords from '../../assets/images/icon-detailed-records.svg'
import CustomizeableImg from '../../assets/images/icon-fully-customizable.svg'

const Features = () => {

    return (
        <section className="section-3" id="section-3">
             <div className="advanced-statistics">
                 <div className="statistics" id="statistics">
                     <h3>Advanced Statistics</h3>
                     <p>Track how your links are performing across the web with our advanced statistics dashboard.</p>
                  </div>
                  
                  <div className="advanced-content">
                     <div className="advanced box1">
                         <div className="icon">
                             <img src={BrandRecognition} alt="brand-recognition"/>
                         </div>
                         <h3> Brand Recognition</h3>
                         <p>Boost your brand recognition with each click. Generic links don’t mean a thing. Branded links help instil confidence in your content.</p>
                      </div>
                      <div className="advanced box2">
                          <div className="icon">
                             <img src={DetailedRecords} alt="detailed-records"/>
                         </div>
                         <h3>Detailed Records</h3>
                         <p>Gain insights into who is clicking your links. Knowing when and where people engage with your content helps inform better decisions.</p>
                      </div>
                      <div className="advanced box3">
                          <div className="icon">
                             <img src={CustomizeableImg} alt="customizable"/>
                         </div>
                         <h3> Fully Customizable</h3>
                         <p>Improve brand awareness and content discoverability through customizable links, supercharging audience engagement.</p>
                      </div>
                  </div>
              </div>
        </section>
    )
}

export default Features