import React, {useState} from 'react'
import './LoginPage.css'
import NavBar from '../../components/navbar/navbar'
import Footer from '../../components/footer/footer'
import Forms from '../../components/login/login'

const LoginPage = () => {
  return (
    <>
      <NavBar/>
      <Forms/>
      <Footer/>
    </>
  );
}

export default LoginPage;
