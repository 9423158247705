import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import './navbar.css'
import Rdre from '../../assets/images/rdre.png'

const NavBar = () => {
  return (
    <div className="container">
      <header>
        <nav>
          <label htmlFor="toggle">☰</label>
          <input type="checkbox" id="toggle" name="" />
          <div className="nav-row">
            <div className="nav-left">
              <div className="logo"><Link to="/"><img src={Rdre} alt="logo" height="auto" width="150px"/></Link></div>
              <ul className="nav-menu">
                <li className="responsive-home"><Link to="/">Home</Link></li>
                <li><Link to="/">Features</Link></li>
                <li><Link to="/p/pricing">Pricing</Link></li>
              </ul>
              </div>
              <hr className="nav-line"/>
              <div className="nav-right">
                <ul className="nav-right-menu">
                  <li><Link to="/p/login">Login</Link></li>
                  <li ><Link to="/p/login">SignUp</Link></li>
                </ul>
              </div>
            </div>
        </nav>
      </header>
    </div>
  );
}

export default NavBar;